import {Hero, Layout, Text, Author} from "components/BlogLayout";
import React                from "react";
import {useArticlesData}    from "shared/Hooks/Articles/useArticlesData";
import {ARTICLE_14}         from "shared/Types/articles";
import SEO                  from "src/components/seo";

const Article_14 = () => {
  const data = useArticlesData(ARTICLE_14);

  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <Text data={data.text.fourth}/>
      <Text data={data.text.fifth}/>
      <Text data={data.text.sixth}/>
      <Text data={data.text.seventh}/>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_14;
